import { createContext, ReactNode, useContext } from "react";
import { login, logout } from "../airtable/psdata";
import { useLocalStorage } from "../utils/useLocalStorage";

interface AuthState {
    isLogged: boolean;
    userName?: string;
    key?: string;
}

type SignInType = (user: string, pwd: string) => Promise<boolean>;
type SignOutType = () => Promise<void>;

interface AuthContextType {
    authState: AuthState;
    signIn: SignInType;
    signOut: SignOutType;
}

const defaultAuthState = { isLogged: false, userName: undefined, key: undefined };
const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function useAuthSignIn(): SignInType {
    return useContext(AuthContext).signIn || null;
}

export function useAuthSignOut(): SignOutType {
    return useContext(AuthContext).signOut || null;
}

export function useAuthState(): AuthState {
    return useContext(AuthContext).authState || defaultAuthState;
}

export function AuthenticationProvider({ children }: { children: ReactNode }) {
    const [loginUser, setLoginUser] = useLocalStorage("loginUser");
    const [key, setKey] = useLocalStorage("key");

    // si hay usuario guardado, intentamos el login aunque a veces se realice dos veces
    if (loginUser) {
        if (!login(loginUser, key || "")) {
            // ... y si falla, borramos el usuario guardado
            setLoginUser(null);
            setKey(null);
        }
    }
    const authContext: AuthContextType = {
        authState: {
            isLogged: !!loginUser,
            userName: loginUser || undefined,
            key: key || undefined
        },
        signIn: (user, pwd) => {
            return new Promise(resolve => {
                // Timeout to simulate async operation
                setTimeout(() => {
                    if (login(user, pwd)) {
                        setLoginUser(user);
                        setKey(pwd);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, 200);
            });
        },
        signOut: () => {
            return new Promise(resolve => {
                // Timeout to simulate async operation
                setTimeout(() => {
                    logout();
                    setLoginUser(null);
                    setKey(null);
                }, 200);
            });
        }
    };
    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );
}
