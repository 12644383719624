import Airtable from "airtable";
import Record from "airtable/lib/record";
import { Shippings } from "../shippings/Shippings";
import { getApiKeyFor } from "./keys";

const BASE_ID = "app7pZKYofJ4hRdB7";

const PRODUCTS_TABLE_ID = "tblsOzGNdtY5atWg8";
const PRODUCTS_SANDALIAS_VIEW_ID = "viwY7OsLoInJywypc";
const SHIPPING_TABLE_ID = "tblMQq8eWsmghw8FB";
const SHIPPING_VIEW_PENDING_ID = "viwO6znDAp1xCwjq2";
const SHIPPING_DETAILS_TABLE_ID = "tblkIvNMeVqouHnzQ";

let api: Airtable | undefined = undefined;

function psData() {
    return api?.base(BASE_ID);
}

export function login(user: string, pwd: string): boolean {
    const apiKey = getApiKeyFor(user, pwd);
    if (apiKey) {
        api = new Airtable({ apiKey });
        return true;
    } else {
        return false;
    }
}

export function logout() {
    api = undefined;
}

export interface SkuDetails {
    sku: string;
    variantName: string;
    attrs: string;
    ean13: string;
}

export async function getProducts(): Promise<SkuDetails[]> {
    let products: SkuDetails[] = [];

    await psData()
        ?.table(PRODUCTS_TABLE_ID)
        .select({ view: PRODUCTS_SANDALIAS_VIEW_ID, sort: [] })
        .eachPage((records, fecthNextPage) => {
            products = products.concat(
                records.map((r) => ({
                    sku: r.get("SKU").toString(),
                    variantName: r.get("N.Variante").toString(),
                    attrs: r.get("N.Atributos")?.toString() || "",
                    ean13: r.get("EAN13")?.toString() || "",
                }))
            );
            fecthNextPage();
        });

    return products;
}

export interface Shipping {
    id: string;
    name: string;
    date: Date;
    target: string;
    qty: number;
    comments: string;
}

export interface BoxDetails {
    size: number;
    items: string[];
}
export interface ItemQtys {
    [sku: string]: number;
}

export interface ShippingDetails extends Shipping {
    id: string;
    name: string;
    date: Date;
    target: string;
    qty: number;
    comments: string;
    details: {
        boxes: BoxDetails[];
    };
    qtys: ItemQtys;
}

function recordToPendingShipping(r: Record): Shipping {
    return {
        id: r.id,
        name: r.get("Nombre") as string,
        date: new Date(r.get("Fecha preparación")),
        target: (r.get("Destino") as string[])[0],
        qty: r.get("Uds.") as number,
        comments: r.get("Comentarios") as string,
    };
}

export async function getPendingShippings(): Promise<Shipping[]> {
    let shippings: Shipping[] = [];
    await psData()
        ?.table(SHIPPING_TABLE_ID)
        .select({
            view: SHIPPING_VIEW_PENDING_ID,
            sort: [{ field: "Fecha preparación", direction: "asc" }],
        })
        .eachPage((records, fecthNextPage) => {
            const received = records.map(recordToPendingShipping);
            shippings = shippings.concat(received);
            fecthNextPage();
        });

    return shippings;
}

export async function getPendingShippingDetails(id: string): Promise<ShippingDetails> {
    const getDetailRecords = async () => {
        const skus: ItemQtys = {};
        await psData()
            ?.table(SHIPPING_DETAILS_TABLE_ID)
            .select({
                filterByFormula: `SEARCH("${id}", {Albarán})`,
            }).eachPage((records, fecthNextPage) => {
                for (const r of records) {
                    const sku = r.get("SKU");
                    if (sku) {
                        skus[sku] += r.get("Cantidad") as number;
                    }
                }
                fecthNextPage();
        });
        return skus;
    };

    const record = await psData()?.table(SHIPPING_TABLE_ID).find(id);
    if (!record) {
        throw ReferenceError(`Envío no encontrado: ${id}`);
    }

    const s = recordToPendingShipping(record);

    const boxes = JSON.parse(record.get("JSON Envío") || "{}");
    const qtyDetails = await getDetailRecords();

    return { ...s, details: boxes, qtys: qtyDetails };
}

export async function savePendingShippingDetails(shipping: ShippingDetails) {
    const record = await psData()?.table(SHIPPING_TABLE_ID).find(shipping.id);
    if (!record) {
        throw ReferenceError(`Envío no encontrado: ${shipping.id}`);
    }

    await psData()
        ?.table(SHIPPING_TABLE_ID)
        .update([
            {
                id: shipping.id,
                fields: {
                    "JSON Envío": JSON.stringify(shipping.details),
                },
            },
        ]);
}

export function getPackedInformationFromShipping(shipping: ShippingDetails) {
    const packed = shipping.details.boxes.reduce((acc, b) => {
        for (let sku of b.items) {
            acc[sku] = acc[sku] + 1;
        }
        return acc;
    }, {} as ItemQtys);

    const pending = Object.keys(shipping.qtys).reduce((acc, k) => {
        acc[k] = shipping.qtys[k] - packed[k];
        return acc;
    }, {} as ItemQtys);

    return [packed, pending] as [ItemQtys, ItemQtys];
}
