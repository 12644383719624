import { simpleDecrypt } from "../utils/simple-crypt";

const keys : { [user: string]: string } = {
    "isa.aznar": "U2FsdGVkX1/KsCH/fECarjVRbT5YW84fnUIMeofbprws9AfECpUzY9JpGqDbx13W",
    "antonio": "U2FsdGVkX18xawIiEbG4ry3SxsLYiBmuVQvtBQ1BTG4eb7xAAk0VDWiztBXN/uDi"
};

export function getApiKeyFor(user: string, pwd: string) {
    return keys[user]
        ? simpleDecrypt(keys[user], user + pwd)
        : null;
}
