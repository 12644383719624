import { Box, Button, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthSignIn, useAuthSignOut, useAuthState } from "./AuthenticationProvider";

export function LoginPage() {
    const history = useHistory();
    const location = useLocation();
    const signIn = useAuthSignIn();
    const signOut = useAuthSignOut();
    const authState = useAuthState();

    const { from } = location.state as any || { from: { pathname: "/" } };

    const doSignin = (user: string, pwd: string) => signIn(user, pwd)
        .then(result => {
            if (result) {
                history.replace(from);
                return "";
            } else {
                return "Usuario y/o contraseña incorrectos";
            }
        });

    const doSignout = () => {
        signOut()
            .then(() => {
                history.replace(from);
            });
    };

    return authState.isLogged ? <LogoutForm onSignout={doSignout} /> : <LoginForm onSignin={doSignin} />;
}

function LogoutForm({ onSignout }: { onSignout: () => void }) {
    return (
        <Button onClick={onSignout}>Sign out</Button>
    );
}

function LoginForm({ onSignin }: { onSignin: (user: string, pwd: string) => Promise<string> }) {
    const [error, setError] = useState("");

    const doSignin = (e: React.FormEvent) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const user = formData.get("user") as string;
        const pwd = formData.get("pwd") as string;
        onSignin(user, pwd).then((errorMsg) => {
            setError(errorMsg);
        });
    };

    return <form onSubmit={doSignin}>
        <Box display="flex" flexDirection="column" mt="40%" p={5}>
            <Box mb={2} textAlign="center">
                <TextField name="user" label="Usuario"></TextField>
            </Box>
            <Box mb={2} textAlign="center">
                <TextField name="pwd" label="Contraseña" type="password"></TextField>
            </Box>
            <Box mb={2} textAlign="center">
                <Button type="submit" variant="contained" color="primary">Acceder</Button>
            </Box>
            <Box mb={2} textAlign="center">
                {error
                    ? <Alert severity="error">{error}</Alert>
                    : <></>}
            </Box>
        </Box>
    </form>;
}

