import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, makeStyles, Paper, Snackbar, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useEffect, useState } from "react";
import {
    Switch as RouteSwitch,
    Route,
    useRouteMatch,
    useParams,
    useHistory
} from "react-router-dom";
import { BoxDetails, getPackedInformationFromShipping, getPendingShippingDetails, getPendingShippings, getProducts, savePendingShippingDetails, Shipping, ShippingDetails, SkuDetails } from "../airtable/psdata";

import { RemoveCircle as RemoveCircleIcon, AddCircle as AddCircleIcon, Close as CloseIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
    itemsTable: {
        "& td": {
            padding: "4px"
        },
        "& th": {
            padding: "4px"
        }
    }
});

export function Shippings() {
    const match = useRouteMatch();
    return (
        <RouteSwitch>
            <Route exact path={`${match.path}/`}>
                <ShippingsList />
            </Route>
            <Route exact path={`${match.path}/boxes/:shippingId`}>
                <Boxes />
            </Route>
            <Route path={`${match.path}/boxes/:shippingId/:boxId`}>
                <PackingBox />
            </Route>
        </RouteSwitch>
    );
}

function ShippingsList() {
    const match = useRouteMatch();
    const [pending, setPending] = useState<Shipping[]>();
    const theme = useTheme();
    const history = useHistory();

    useEffect(() => {
        getPendingShippings().then(r => setPending(r));
    }, []);

    if (!pending) {
        return <LinearProgress />;
    }

    return <Box height="100%" display="flex" flexDirection="column" bgcolor={theme.palette.background.default}>
        <Box ml={2} mt={1}>
            <Typography style={{ flex: "1 0" }} variant="h6">Envíos pendientes</Typography>
        </Box>
        <Box ml={2} mr={2} mt={1}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Envío</TableCell>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Uds.</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pending.map((p) => <React.Fragment key={p.id}>
                            <TableRow onClick={() => history.push(`${match.url}/boxes/${p.id}`)}>
                                <TableCell style={{ paddingBottom: "0", borderBottom: "none" }}>{p.name}</TableCell>
                                <TableCell style={{ paddingBottom: "0", borderBottom: "none" }}>{p.date.toLocaleDateString()}</TableCell>
                                <TableCell style={{ paddingBottom: "0", borderBottom: "none" }}>{p.qty}</TableCell>
                            </TableRow>
                            <TableRow onClick={() => history.push(`${match.url}/boxes/${p.id}`)}>
                                <TableCell style={{ paddingTop: "0" }}></TableCell>
                                <TableCell style={{ paddingTop: "0" }} colSpan={2}>
                                    <Typography variant="caption" color="textSecondary">
                                        {p.comments || <span>&nbsp;</span>}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </Box>;
}

function Boxes() {
    const { shippingId } = useParams<{ shippingId: string }>();
    const [shipping, setShipping] = useState<ShippingDetails>();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showNewBoxDialog, setShowNewBoxDialog] = useState(false);
    const match = useRouteMatch();
    const history = useHistory();
    const theme = useTheme();

    useEffect(() => {
        getPendingShippingDetails(shippingId)
            .then(r => setShipping(r))
            .catch(e => setErrorMsg(`Error: "${e.toString()}"`));
    }, [shippingId]);

    if (errorMsg) {
        return (
            <Alert severity="error"
                onClose={() => window.location.reload()}>
                {errorMsg}
            </Alert>);
    }

    if (!shipping) {
        return <LinearProgress />;
    }

    const doAcceptNewBoxDialog = (ok: boolean, size: number, boxes: BoxDetails[]) => {
        setShowNewBoxDialog(false);
        if (ok) {
            const newBoxes = [...boxes, { size: size, items: [] }];
            const newShipping = { ...shipping, details: { ...shipping?.details, boxes: newBoxes } };
            savePendingShippingDetails(newShipping)
                .then(() => {
                    setShipping(newShipping);
                })
                .catch((reason) => setErrorMsg(reason.toString()));
        }
    };

    const doDeleteBoxAt = (ok: boolean, boxIndex: number, boxes: BoxDetails[]) => {
        if (ok) {
            const newBoxes = boxes.filter((b, i) => i !== boxIndex);
            const newShipping = { ...shipping, details: { ...shipping?.details, boxes: newBoxes } };
            savePendingShippingDetails(newShipping)
                .then(() => {
                    setShipping(newShipping);
                })
                .catch((reason) => setErrorMsg(reason.toString()));
        }
    };


    const boxes = shipping.details.boxes || [];

    const packed = boxes.reduce((acc, box) => acc + box.items.length, 0);

    return <Box height="100%" display="flex" flexDirection="column" bgcolor={theme.palette.background.default}>
        <Box ml={2} mt={1}>
            <Typography style={{ flex: "1 0" }} variant="h6">{shipping.name}</Typography>
        </Box>
        <Box mr={2} mt={1} textAlign="right">
            <Typography>{`Cajas: ${boxes.length}, uds: ${packed}, faltan: ${shipping.qty - packed}`}</Typography>
        </Box>
        <Box ml={2} mr={2} mt={1}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Caja</TableCell>
                            <TableCell align="right">Capacidad</TableCell>
                            <TableCell align="right">Uds.</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {boxes.map((b, i) => <TableRow key={i}>
                            <TableCell align="center">
                                <Button
                                    variant="outlined"
                                    onClick={() => history.push(`${match.url}/${i.toString()}`)}>
                                    {i + 1}
                                </Button>
                            </TableCell>
                            <TableCell align="right">
                                {b.size}
                            </TableCell>
                            <TableCell align="right">
                                {b.items.length}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    style={{ margin: "0", paddingRight: "4px" }}
                                    onClick={() => doDeleteBoxAt(true, i, boxes)}>
                                    <RemoveCircleIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        <Box mt={1} mr={2} display="flex" justifyContent="flex-end">
            <Button
                variant="contained"
                color="primary"
                onClick={() => setShowNewBoxDialog(true)}>
                <Typography variant="h6">+</Typography>
            </Button>
            <NewBoxDialog
                visible={showNewBoxDialog}
                onClose={(ok, size) => doAcceptNewBoxDialog(ok, size, boxes)} />
        </Box>
    </Box>;
}

function NewBoxDialog({ visible, onClose }: {
    visible: boolean;
    onClose: (ok: boolean, size: number) => void;
}) {
    const [size, setSize] = useState(28);
    return <Dialog
        open={visible}
        onClose={() => onClose(false, 0)}>
        <DialogTitle>Nueva caja</DialogTitle>
        <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <TextField
                type="number"
                value={size.toString()}
                onChange={e => setSize(e.target.value ? parseInt(e.target.value) : 0)}
                label="Capacidad" />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose(false, 0)} color="primary">Cancelar</Button>
            <Button onClick={() => onClose(true, size)} color="primary">Aceptar</Button>
        </DialogActions>
    </Dialog>;
}

function PackingBox() {
    const { shippingId, boxId } = useParams<{ shippingId: string, boxId: string }>();
    const [shipping, setShipping] = useState<ShippingDetails>();
    const [products, setProducts] = useState<SkuDetails[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showAddItem, setShowAddItem] = useState(false);
    const [savingState, setSavingState] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [scaner, setScaner] = useState(false);
    const [grouped, setGrouped] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        getPendingShippingDetails(shippingId)
            .then(r => setShipping(r))
            .catch(e => setErrorMsg(`Error: "${e.toString()}"`));
        getProducts()
            .then(p => setProducts(p))
            .catch(e => setErrorMsg(`Error: "${e.toString()}"`));
    }, [shippingId]);


    if (errorMsg) {
        return (
            <Alert severity="error"
                onClose={() => window.location.reload()}>
                {errorMsg}
            </Alert>);
    }

    if (!shipping) {
        return <LinearProgress />;
    }

    const boxIndex = parseInt(boxId);
    const box = shipping.details?.boxes[boxIndex];
    const packed = box.items.length;
    const [packedDetails, pendingDetails] = getPackedInformationFromShipping(shipping);

    if (!box) {
        setErrorMsg(`No se encuentra la caja ${boxId}`);
        return <></>;
    }

    const doAcceptAddItem = (ok: boolean, sku: string) => {
        setShowAddItem(false);
        if (ok && sku) {
            const newShipping = { ...shipping };
            newShipping.details.boxes[boxIndex].items = [...box.items, sku];
            setSavingState(true);
            savePendingShippingDetails(newShipping)
                .then(() => {
                    setShipping(newShipping);
                    setMessageText("Elemento añadido a la caja");
                    setSavingState(false);
                })
                .catch((reason) => setErrorMsg(reason.toString()));
        }
    };

    const doDeleteItemAt = (ok: boolean, index: number) => {
        if (ok) {
            const newShipping = { ...shipping };
            newShipping.details.boxes[boxIndex].items = box.items.filter((b, i) => i !== index);
            setSavingState(true);
            savePendingShippingDetails(newShipping)
                .then(() => {
                    setShipping(newShipping);
                    setMessageText("Elemento quitado de la caja");
                    setSavingState(false);
                })
                .catch((reason) => setErrorMsg(reason.toString()));
        }
    };

    const doDeleteItemBySku = (ok: boolean, sku: string) => {
        if (ok) {
            const newShipping = { ...shipping };
            const index = box.items.findIndex(s => s === sku);
            newShipping.details.boxes[boxIndex].items = box.items.filter((b, i) => i !== index);
            setSavingState(true);
            savePendingShippingDetails(newShipping)
                .then(() => {
                    setShipping(newShipping);
                    setMessageText("Elemento quitado de la caja");
                    setSavingState(false);
                })
                .catch((reason) => setErrorMsg(reason.toString()));
        }
    };

    return <>
        <Box
            display="flex"
            flexDirection="column"
            flex="1 1 auto"
            overflow="auto"
            bgcolor={theme.palette.background.paper}>
            <Box display="flex" flexDirection="column" flex="1 1 auto" overflow="auto">
                <Box ml={2} mt={1} flex="0 1 auto">
                    <Typography variant="h6">
                        {`Envío: ${shipping.name}, caja: ${parseInt(boxId) + 1}`}
                    </Typography>
                </Box>
                <Box ml={2} mt={1} mb={1} flex="0 1 auto" display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flex="1 0">
                        <Typography>
                            {`Uds: ${packed}, faltan: ${box.size - packed}`}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" flex="0" marginRight="1em">
                        <Switch
                            checked={grouped}
                            onChange={() => setGrouped(!grouped)}
                            color="primary" />
                        <Typography>Agrupados</Typography>
                    </Box>
                </Box>
                <Box mt={1} flex="1 1 auto">
                    {grouped ? <GroupedTable items={box.items} /> : <ItemsTable items={box.items} />}
                </Box>
            </Box>
            <Box ml={2} mr={2} mt={2} display="flex" flexDirection="column" flex="0 1 auto">
                {savingState && <LinearProgress style={{ marginBottom: "4px" }} />}
                <Box display="flex" flexDirection="row" flex="0 1" mb={3}>
                    <Box display="flex" flexDirection="row" alignItems="center" flex="1 0">
                        <Switch
                            checked={scaner}
                            onChange={() => setScaner(!scaner)}
                            color="primary" />
                        <Typography>Escáner</Typography>
                    </Box>
                    <Button color="primary" variant="contained" onClick={() => setShowAddItem(true)}>
                        Añadir manualmente
                </Button>
                    <AddItemToBox visible={showAddItem} products={products} onClose={doAcceptAddItem} />
                </Box>
                <Box height="20vh" display={scaner ? "flex" : "none"} flex="1 0" bgcolor="#0000FF30" mb={3} border="1px solid #00000040" borderRadius={16}>
                    &nbsp;
            </Box>
            </Box>
        </Box>
        <Snackbar
            open={!!messageText}
            autoHideDuration={3000}
            message={messageText}
            onClose={() => setMessageText("")}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setMessageText("")}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        />
    </>;

    function ItemsTable({ items }: { items: string[] }) {
        const classes = useStyles();
        return (
            <TableContainer component={Paper} className={classes.itemsTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell colSpan={4} align="center">Ítem</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((sku, i) => <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{sku}</TableCell>
                            <TableCell>{products.find(p => p.sku === sku)?.variantName}</TableCell>
                            <TableCell>{products.find(p => p.sku === sku)?.attrs}</TableCell>
                            <TableCell>
                                <IconButton
                                    style={{ margin: "0", paddingRight: "4px" }}
                                    onClick={() => doDeleteItemAt(true, i)}>
                                    <RemoveCircleIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>)
                            .reverse()}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    function GroupedTable({ items }: { items: string[] }) {
        const classes = useStyles();
        const grouped = items.reduce((acc, sku) => {
            acc[sku] = (acc[sku] || 0) + 1;
            return acc;
        }, {} as { [sku: string]: number });
        return (
            <TableContainer component={Paper} className={classes.itemsTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Ítem</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(grouped).map((sku, i) => <TableRow key={i}>
                            <TableCell>{sku}</TableCell>
                            <TableCell><b>{products.find(p => p.sku === sku)?.variantName}&nbsp;{products.find(p => p.sku === sku)?.attrs}</b></TableCell>
                            <TableCell align="right"><b>{grouped[sku]}</b></TableCell>
                            <TableCell>
                                <Box display="flex" flexDirection="row" alignItems="center" flex="1 0">
                                    <IconButton
                                        style={{ margin: "0", paddingRight: "4px" }}
                                        onClick={() => doDeleteItemBySku(true, sku)}>
                                        <RemoveCircleIcon />
                                    </IconButton>
                                    <IconButton
                                        style={{ margin: "0", paddingRight: "4px" }}
                                        onClick={() => doAcceptAddItem(true, sku)}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>)
                            .reverse()}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

function AddItemToBox({ visible, products, onClose }: {
    visible: boolean;
    products: SkuDetails[];
    onClose: (ok: boolean, sku: string) => void;
}) {
    const [filter, setFilter] = useState("");
    return <Dialog
        scroll="paper"
        open={visible}
        onClose={() => onClose(false, "")}>
        <DialogTitle>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Selecciona un producto</Typography>
                <IconButton onClick={() => onClose(false, "")}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Box>
                <TextField value={filter} onChange={e => setFilter(e.target.value || "")} label="Buscar" />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {products
                            .filter(p => p.sku.toLowerCase().includes(filter)
                                || p.variantName.toLowerCase().includes(filter)
                                || p.attrs.toLowerCase().includes(filter))
                            .map(p =>
                                <TableRow key={p.sku}
                                    onClick={() => onClose(true, p.sku)}>
                                    <TableCell>{p.sku}</TableCell>
                                    <TableCell><b>{p.variantName}</b></TableCell>
                                    <TableCell>{p.attrs}</TableCell>
                                </TableRow>)}

                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
    </Dialog>;
}
