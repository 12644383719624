import { useEffect, useState } from "react";

export function useLocalStorage(key: string) {
    // initialize the value from localStorage
    const [currentValue, setCurrentValue] = useState(() => localStorage.getItem(key));

    // on every render, re-subscribe to the storage event
    useEffect(() => {
        const handler = (e: StorageEvent) => {
            if (e.storageArea === localStorage && e.key === key) {
                setCurrentValue(e.newValue);
            }
        };
        window.addEventListener("storage", handler);
        return () => {
            window.removeEventListener("storage", handler);
        };
    });

    useEffect(() => {
        if (currentValue) localStorage.setItem(key, currentValue);
        else localStorage.removeItem(key);
    }, [key, currentValue]);

    // use as const to tell TypeScript this is a tuple
    return [currentValue, setCurrentValue] as const;
}
