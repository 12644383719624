import { ReactNode } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthState } from "./AuthenticationProvider";

export function PrivateRoute({ children, ...rest }: { children: ReactNode, [key: string]: any }) {
  let auth = useAuthState();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isLogged ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
