import CryptoJS from "crypto-js";

export function simpleCrypt(text: string, key: string) {
    return CryptoJS.AES.encrypt(text, key).toString();
}

export function simpleDecrypt(ciphertext: string, key: string) {
    var bytes = CryptoJS.AES.decrypt(ciphertext, key);
    return bytes.toString(CryptoJS.enc.Utf8);
}
