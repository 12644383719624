import React, { useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    useLocation
} from "react-router-dom";

import { AuthenticationProvider, useAuthState, useAuthSignOut } from "./authentication/AuthenticationProvider";
import { PrivateRoute } from "./authentication/PrivateRoute";
import { LoginPage } from "./authentication/Login";

import { Shippings } from "./shippings/Shippings";
import { AppBar, Toolbar, IconButton, Button, Typography, Drawer, ListItemIcon, ListItem, List, ListItemText, Box } from "@material-ui/core";

import {
    Menu as MenuIcon,
    ArrowBack as ArrowBackIcon,
    ExitToApp as ExitToAppIcon,
    Public as PublicIcon,
    LocalShipping as LocalShippingIcon
} from "@material-ui/icons";

export default function App() {
    return (
        <AuthenticationProvider>
            <Router>
                <Box flex="0 1 auto">
                    <AppHeader />
                </Box>
                <Switch>
                    <Route path="/public">
                        <PublicPage />
                    </Route>
                    <Route path="/login">
                        <LoginPage />
                    </Route>
                    <PrivateRoute path="/shippings">
                        <Shippings />
                    </PrivateRoute>
                </Switch>
            </Router>
        </AuthenticationProvider>
    );
}

function SideNav() {
    const history = useHistory();
    const location = useLocation();
    const [isOpen, setOpen] = useState(false);

    // XXXX
    const pathLength = location.pathname.split("/").length;

    const doToggle = () => setOpen(!isOpen);
    const doNavigate = (url: string) => {
        history.push(url);
        setOpen(false);
    };

    return <>
        {pathLength > 2 ? <ArrowBackIcon onClick={() => history.goBack()}/> : <MenuIcon onClick={doToggle} />}
        <Drawer anchor="left" open={isOpen} onClose={doToggle}>
            <List>
                <ListItem button onClick={() => doNavigate("/public")}>
                    <ListItemIcon>
                        <PublicIcon />
                    </ListItemIcon>
                    <ListItemText primary="Public page" />
                </ListItem>
                <ListItem button onClick={() => doNavigate("/shippings")}>
                    <ListItemIcon>
                        <LocalShippingIcon />
                    </ListItemIcon>
                    <ListItemText primary="Envíos pendientes" />
                </ListItem>
            </List>
        </Drawer>
    </>;
}

function AppHeader() {
    let history = useHistory();
    let authState = useAuthState();
    let signOut = useAuthSignOut();

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" color="inherit">
                    <SideNav />
                </IconButton>
                <Typography style={{ flex: "1 0" }} variant="h6">PSData</Typography>
                {authState.isLogged
                    ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography variant="button">{authState.userName}</Typography>
                        <IconButton
                            onClick={() => signOut().then(() => history.push("/"))}
                            style={{ paddingRight: "0" }}
                            color="inherit">
                            <ExitToAppIcon />
                        </IconButton>
                    </div>
                    : <Button
                        onClick={() => history.push("/login")}
                        color="inherit">
                        Entrar
                        </Button>}
            </Toolbar>
        </AppBar>
    );
}

function PublicPage() {
    return <h3>Public</h3>;
}

